<template>
  <div class="tipzixun">
    <el-dialog
      :title="title"
      :visible.sync="dialogVisible"
      width="375px"
      :before-close="handleClose"
      center>
    <span>{{text}}</span>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">取 消</el-button>
      <el-button type="primary"  v-if="type == 1 || type == 3" @click.stop="sure">确 定</el-button>
      <el-button type="primary" @click="topingjia" v-if="type == 2">去评价</el-button>
  </span>
</el-dialog>
  </div>
</template>

<script>
import apiUrl from '@/api/main'
export default {
  name: '',
  components: {},
  props: {
    
  },
  data() {
    return {
      dialogVisible:false,
      title:'提示',
      text:'', // 提示内容
      type:1, // 1审核中  2确认完成去评价 3 撤销售后
      orderinfo:{}, // 订单详情
    }
  },

  mounted() {
   
  },

  methods: {
    // 审核中1  2确认完成去评价
    open(type,data){
      this.dialogVisible = true;
      this.type = type;

      if(this.type == 1){
      this.title = '审核中提示'
      this.text = '您好，您的问题我们已经收到，请耐心等待审核。'
      }else if(this.type == 2){
        this.title = '确认完成提示'
        this.text = '满意律师解答，可给出五星好评哦！'
      }else if(this.type == 3){
        this.title = '撤销提示'
        this.text = '确定要撤销售后申请吗？'
      }

      this.orderinfo = data;
    },

    handleClose(){
      this.dialogVisible = false;
    },
    // 去评价
    topingjia(){
      this.$router.push({
        path: '/pingjia?id=' + this.orderinfo.id
      })
    },
    // 撤销售后3 审核中1
    sure(){
      if(this.type == 3){
        this.chexiao();
      }
      this.dialogVisible = false;
      
    },
    // 撤销售后
    chexiao(){
      let data = {
        id: this.orderinfo.id,
      }
      apiUrl.cexiaoshouhouorder(data).then(() => {
          this.$message.success('操作成功');
          this.$emit('refresh');
      });
    }
  }
}
</script>

<style lang="scss">


</style>