<template>
    <div class="zixunindex">
        <div class="topnav">
          <span :class="[navItem == -1 ? 'active' : '']" @click.stop="changeNav(-1)">全部</span>
          <span :class="[navItem == -2 ? 'active' : '']" @click.stop="changeNav(-2)">待支付</span>
          <span :class="[navItem == 0 ? 'active' : '']" @click.stop="changeNav(0)">审核中</span>
          <span :class="[navItem == 1 ? 'active' : '']" @click.stop="changeNav(1)">待解答</span>
          <span :class="[navItem == 2 ? 'active' : '']" @click.stop="changeNav(2)">待评价</span>
          <span :class="[navItem == 3 ? 'active' : '']" @click.stop="changeNav(3)">已完成</span>
          <span :class="[navItem == 4 ? 'active' : '']" @click.stop="changeNav(4)">售后</span>
        </div>

        <div class="main">
          <div class="mainitem" v-for="(item,i) in list" :key="i" @click.stop="toDetail(item)">
            <div class="mi1 flex_between">
              <div class="min1left flexbox">
                <!-- <img src="@/assets/img/icon.png" alt=""> -->
                <img :src="item.typePhoto" alt="" v-if="!item.lawyerId">
                <img :src="item.lsLawyer.photo" alt="" v-else>
                <p v-if="item.lawyerId && navItem == 2"> {{item.lsLawyer.lawyerName}}律师   <span>已回复</span></p>
                <p v-else>您发起了{{item.type}}  <span>￥{{item.price}}</span></p>
              </div>
           
              <div  class="min1right Colour" v-if="item.orderStatusText == '待支付'"> 待支付 </div>
              <div  class="min1right Colour" v-else-if="item.orderStatusText == '待解答'"> 待解答 </div>
              <div  class="min1right Colour" v-else-if="item.orderStatusText == '待审核'" @click.stop="tipbtn(1,item)">审核中</div>
              <div  class="min1right defaultColor" v-else-if="item.orderStatusText == '售后'" @click.stop="tourl('/shouhou?id=' + item.id)">售后</div>
              <div class="min1right defaultColor" v-else> {{item.orderStatusText}} </div>

            </div>
            <div class="mi2">{{item.content}} <span v-if="item.fenglei">问题类型：{{item.fenglei}}</span></div>
            <div v-if="item.photos" class="photosimg">
              <img :src="tag" mode="aspectFit" 
              v-for="(tag,index) in item.photos.split(',')" :key="index">
            </div>
           <p class="mi3">{{item.createTime | fifterTime}}</p>
           <div class="mi4"></div>
           <div class="mi5 flexbox">

            <div v-show="item.payStatus == 1&&item.orderStatus == 2&&item.type!='真假难辨'" class="flexbox">
              <div class="btnbox zhuiwen" @click.stop="toLaywer(item)">追问</div>
              <div class="btnbox" @click.stop="tourl('/pingjia?id=' + item.id)" v-if="item.pingjiaStatus==0">立即评价</div>
            </div>

            <div v-show="item.payStatus == 1&&item.orderStatus == 3&&item.type!='真假难辨'" class="btnbox"  @click.stop="tourl('/shouhou?id=' + item.id)">申请售后</div>
            <div class="btnbox zhuiwen" @click.stop="tipbtn(3,item)"  v-show="item.payStatus == 1&&item.orderStatus == 4">撤销售后</div>
            <div class="btnbox" @click.stop="topay(item)"  v-show="item.payStatus == 0">去支付</div>

            <div class="btnbox" @click.stop="tipbtn(2,item)" v-show="item.orderStatusText == '已回复' && item.payStatus != 0 && item.lawyerId">确认完成</div>
           </div>
          </div>
        </div>

        <tipzixun ref="tips" @refresh="refresh"></tipzixun>
        <paytype ref="pay" :typeNum="2" @refresh="refresh"></paytype>

        <div class="paginationblock">
          <el-pagination
            :hide-on-single-page="true"
            @current-change="handleCurrentChange"
            :current-page.sync="pagination.currentPage"
            :page-size="pagination.size"
            layout="total, prev, pager, next"
            :total="pagination.total">
          </el-pagination>
        </div>

        <NoData v-if="list.length <= 0"></NoData>
    </div>
</template>

<script>
import tipzixun from '@/components/tipzixun'
import paytype from '@/components/tip/paytype'
import NoData  from '@/components/NoData'

import apiUrl from '@/api/main'
import resetTime from "@/util/check.js";
export default {
    components: {tipzixun,paytype,NoData},
    data() {
        return {
          navItem:-1, // -1全部  -2待支付  0审核中   1待解答  2待评价  3已完成  4售后
          pagination:{
            size:3, // 每页几条
            currentPage: 1,
            total:0
          },
          list:[],
          userimID: localStorage.getItem("userimID"), //用户手机号 ，im userid
				  userID: localStorage.getItem("userid"), //用户id
				  userSig: localStorage.getItem("userSig"),
        };
    },
    filters: {
			fifterTime(value) {
				let time = value ? value.replace(/-/g, '/') : '';
				if (time) {
					return resetTime.timeago(new Date(time).getTime());
				}
			}
		},
    computed: {},
    watch: {},
    created() {},
    mounted() {
      this.getlist();

      if (this.userID) {
				this.$tim.login({
					userID: 'u' + this.userimID,
					userSig: this.userSig
				});
		  }
    },
    methods: {
      // 改变 nav
      changeNav(i){
        this.pagination.currentPage = 1;
        this.navItem = i;
        this.getlist();
      },
      // 支付
      topay(data){
        this.$refs.pay.openpay(data,data.id);
      },
      // 跳转
      tourl(url) {
        this.$router.push({
          path: url
        })
      },
      // 1审核中  2确认完成  3撤销售后
      tipbtn(i,item){
        this.$refs.tips.open(i,item);
      },
      toDetail(item) {
				

				if (item.lawyerId) {
					this.searchUser(item.lsLawyer.phone, item.lsLawyer.lawyerName, item.zhuiwencishu, item.id)
					// 连接律师好友
				} else {
          this.$message('当前没有律师回复');
				}

        if (item.payStatus == 0) {
					//未支付时提示
          this.$message('您好，您的问题未付费，请先付费');
				} else if (item.orderStatus == 0) {
					//审核中提示
          this.$message('您好，您的问题我们已收到，请耐心等待审核。');
				}
			},
      // 追问
      toLaywer(item) {
        console.log(item,'追问')
				//追问次数
				// if (item.zhuiwencishu <= 0) {
				// 	this.content = "您的追问次数已用完";
				// 	this.showPayModel = true;
				// 	return;
				// }
     
				this.searchUser(item.lsLawyer.phone, item.lsLawyer.lawyerName, item.zhuiwencishu, item.id);
			},

      searchUser(lawyerphone, lawyername, zhuiwencishu, orderid) {

				// 确定该律师是否已经注册im
				this.$tim.getUserProfile({
						userIDList: ['l' + lawyerphone]
					})
					.then(({
						data
					}) => {
						if (data.length === 0) {
              this.$message('未找到该律师信息');
							return
						}
						
            let path = this.$router.resolve({
              path: '/chat?lawyerphone=' + lawyerphone
            });
            // 打开一个新的页面     
            window.open(path.href, '_blank');

					}).catch((error) => {
						console.log(error,'--=-=-=-=-=-=-=-=-=-=--=-=')
					})
			},
      // 获取订单列表
      getlist(){
        let data = {
          userId:localStorage.getItem('userId'),
          // orderStatus:0, // 订单状态 0待审核 1待解答  2待评价 3已完成  -1已取消  4售后
          // payStatus:0, // 支付状态 0未支付 1已支付
          pageNum: this.pagination.currentPage,
					pageSize: this.pagination.size,
        }

        if(this.navItem != -1){
          if(this.navItem == -2){
            data['payStatus'] = 0;
          }else {
            data['payStatus'] = 1;
            data['orderStatus'] = this.navItem;
          }
        }

        apiUrl.userOrderList(data).then((res) => {
          this.list = res.rows;
          this.pagination.total = res.total;
          if (res.rows.length <= 0) return;
          res.rows.map(item => {
							if (item.payStatus == 0) {
								item.orderStatusText = "待支付";
							} else {
								if (item.orderStatus == 0) {
									item.orderStatusText = "待审核";
								} else if (item.orderStatus == 1) {
                 
									item.orderStatusText = "待解答";

									// if(item.lawyerId){
									// 	item.orderStatusText = "已回复";
									// }else{
									// 	item.orderStatusText = "待解答";
									// }
								} else if (item.orderStatus == 2) {
                  item.orderStatusText = "待评价";
								} else if (item.orderStatus == 3) {
									item.orderStatusText = "已完成";
								} else if (item.orderStatus == -1) {
									item.orderStatusText = "已取消";
								} else if (item.orderStatus == 4) {
									item.orderStatusText = "售后";
								}
							}
						});
           
				});
      },
      handleCurrentChange(val) {
        console.log(`当前页: ${val}`);
        this.pagination.currentPage = val
        this.getlist();
      },
      refresh(){
        // this.pagination.currentPage = 1;
        this.list = [];
        this.getlist();
        
      }

    },
  
    }
</script>
<style lang='scss' scoped>
//@import url(); 引入公共css类
.zixunindex{
  // padding: 33px;
  // background-color: #F8F8F8;
  .topnav{
    width: 1216px;
    background: #FFFFFF;
    border-radius: 13px;
    padding: 0 48px;
    height: 72px;
    line-height: 72px;
    span{
      display: inline-block;
      margin-right: 38px;
      cursor: pointer;
      height: 72px;
      width: 72px;
      text-align: center;
      font-size: 18px;
      font-weight: 500;
    }
    span.active{
      border-bottom: 3px solid #305BFE;
      color: #305BFE ;
    }
  }

  .main{
    .mainitem{
      margin-top: 16px;
      width: 1216px;
      background: #FFFFFF;
      border-radius: 16px;
      padding:32px 32px 16px 32px;
      .mi1{
        .min1left{
          img{
            width: 44px;
            height: 44px;
            margin-right: 16px;
          }
          p{
            font-size: 20px;
            font-weight: 500;
            color: #333333;
           
            span{
                font-size: 20px;
                font-weight: 500;
                color: #F34933;
                margin-left: 32px;
            }
          }
        }
        .min1right{
            font-size: 18px;
            cursor: pointer;
        }
        .Colour{
          color: #FF8011;
        }
        .defaultColor{
          color: #777777;
        }
      }
      .mi2{
        margin-top: 13px;
        font-size: 16px;
        color: #333333;
        padding-left: 50px;
      
        span{
          font-size: 16px;
          color: #777777;
          margin-left: 13px;
        }
      }
      .mi3{
        font-size: 14px;
        color: #777777;
        margin-top: 16px;
        padding-left: 50px;
      }
      .mi4{
        // width: 910px;
        height: 1px;
        background: #F5F5F5;
        margin-top: 12px;
      }
      .mi5{
        justify-content: flex-end;
        margin-top: 13px;
        .btnbox{
          width: 120px;
          height: 46px;
          line-height: 46px;
          border-radius: 36px;
          font-size: 18px;
        }
        .zhuiwen{
          background: #fff;
          border-radius: 30px;
          border: 1px solid #CCCCCC;
          color: #777;
          margin-right: 17px;
        }
        .r17{
          margin-right: 17px;
        }
      }
    }
    .photosimg{
      img{
        width: 128px;
        height: 146px;
        margin: 26px 48px 0 0;
      }
    }
  }
}
</style>